<script>
	//init
	let userObject = null;
	const userbase = window.userbase;
	const appId = 'c265b894-144c-41ed-97e2-a012e516920a';
	let authPromise = userbase.init({appId: 'c265b894-144c-41ed-97e2-a012e516920a'})
		.then(({user}) => userObject = user);

	//auth
	let username, password;

	const signIn = () => 
		authPromise = userbase.signIn({username, password}).then(user => userObject = user);
	const signUp = () => 
		authPromise = userbase.signUp({username, password}).then(user => userObject = user);
	const signOut = () => 
		authPromise = userbase.signOut().then(() => userObject = null);

	$: console.log(userObject);

	//db
	let todoPromise;
	let todos = [], newTodo = '';
	const databaseName = 'todos';
	function changeHandler(items) {
		todos = items
	}
	$: if (userObject) todoPromise = userbase.openDatabase({databaseName, changeHandler});
	function addTodo() {
		todoPromise = userbase.insertItem({databaseName, item: newTodo})
		newTodo = '';
	}
	function deleteTodo(itemId){
		todoPromise = userbase.deleteItem({databaseName, itemId});
	}
	function updateTodo(index){
		const {item, itemId} = todos[index];
		todoPromise = userbase.updateItem({databaseName, itemId, item});
	}
</script>

{#await authPromise}Loading...{:then _}
	{#if !userObject}
		<form>
			<label for="username">Username</label>
			<input id="username" type="text" bind:value={username}>

			<label for="password">Password</label>
			<input id="password" type="password" bind:value={password}><br>

			<button on:click={signIn} type="button">Sign in</button>
			<button on:click={signUp} type="button">Sign up</button>
		</form>
	{:else}
		<h1>Hi, {userObject.username}</h1>
		<button on:click={signOut}>Sign out</button>

		{#await todoPromise}Loading todos..{:then _}
			<label for="new-todo">New Todo</label>
			<input id="new-todo" type="text" bind:value={newTodo}>
			<button on:click={addTodo}>Add</button>

			{#each todos as {item, itemId}, index}
				<br>
				<input type="text" bind:value={todos[index].item} on:blur={() => updateTodo(index)}>
				<button on:click={() => deleteTodo(itemId)}>X</button>
			{/each}
		{:catch error} Error! {error} {/await}
	{/if}
{:catch error} Error! {error} {/await}